.swal2-container {
  z-index: 20000 !important;
}
.loader {
    box-sizing: border-box;
    display: inline-block;
    width: 50px;
    height: 80px;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    position: relative;
    background: linear-gradient(#2b383e 30px, transparent 0) no-repeat;
    background-size: 2px 40px;
    background-position: 50% 0px;
    animation: spinx 5s linear infinite;
  }
  .loader:before, .loader:after {
    content: "";
    width: 40px;
    left: 50%;
    height: 35px;
    position: absolute;
    top: 0;
    transform: translatex(-50%);
    background: rgba(255, 255, 255, 0.4);
    border-radius: 0 0 20px 20px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 0 0px;
    animation: lqt 5s linear infinite;
  }
  .loader:after {
    top: auto;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    animation: lqb 5s linear infinite;
  }
  @keyframes lqt {
    0%, 100% {
      background-image: linear-gradient(#2b383e 40px, transparent 0);
      background-position: 0% 0px;
    }
    50% {
      background-image: linear-gradient(#2b383e 40px, transparent 0);
      background-position: 0% 40px;
    }
    50.1% {
      background-image: linear-gradient(#2b383e 40px, transparent 0);
      background-position: 0% -40px;
    }
  }
  @keyframes lqb {
    0% {
      background-image: linear-gradient(#2b383e 40px, transparent 0);
      background-position: 0 40px;
    }
    100% {
      background-image: linear-gradient(#2b383e 40px, transparent 0);
      background-position: 0 -40px;
    }
  }
  @keyframes spinx {
    0%, 49% {
      transform: rotate(0deg);
      background-position: 50% 36px;
    }
    51%, 98% {
      transform: rotate(180deg);
      background-position: 50% 4px;
    }
    100% {
      transform: rotate(360deg);
      background-position: 50% 36px;
    }
  }
      
.LoginContainer{
    width: 100%;
    height: 100%;

    display: flex;

    .panelLeft{

    }

    .panelRight{

    }
}

.backloader{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.479);
    z-index: 200;

}

.backloader2{
  //position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.479);
  z-index: 200;
  
}

.Apuntes{
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #ffffff;
  background-color: #03292e;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.swal-wide{
  width:850px !important;
}



.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2b383e;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  background-color: #000000;
}

.container1 {
  width: 100%;
  height: 100%;
  background-color: #0c0c0c;
  overflow: hidden;
  position: relative;
}

.background1 {
  display: block;
  position: absolute;
  background: #0c0c0c;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;

  mask-image: radial-gradient(
    white 0%,
    white 30%,
    transparent 80%,
    transparent
  );
}

.circle-container1 {
  $particleNum: 200;
  $particleColor: hsl(180, 100%, 80%);

  position: absolute;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
    background-image: radial-gradient(
      hsl(180, 100%, 80%),
      hsl(180, 100%, 80%) 10%,
      hsla(180, 100%, 80%, 0) 56%
    );

    animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

    @keyframes fade-frames {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.7;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes scale-frames {
      0% {
        transform: scale3d(0.4, 0.4, 1);
      }

      50% {
        transform: scale3d(2.2, 2.2, 1);
      }

      100% {
        transform: scale3d(0.4, 0.4, 1);
      }
    }
  }

  $particleBaseSize: 8;

  @for $i from 1 through $particleNum {
    &:nth-child(#{$i}) {
      $circleSize: random($particleBaseSize);
      width: $circleSize + px;
      height: $circleSize + px;

      $startPositionY: random(10) + 100;
      $framesName: "move-frames-" + $i;
      $moveDuration: 28000 + random(9000) + ms;

      animation-name: #{$framesName};
      animation-duration: $moveDuration;
      animation-delay: random(37000) + ms;

      @keyframes #{$framesName} {
        from {
          transform: translate3d(random(100) + vw, $startPositionY + vh, 0);
        }

        to {
          transform: translate3d(random(100) + vw, -$startPositionY - random(30) + vh, 0);
        }
      }

      .circle {
        animation-delay: random(4000) + ms;
      }
    }
  }
}

.message {
  position: absolute;
  right: 20px;
  bottom: 10px;
  color: rgba(255, 232, 107, 0.47);
  font-family: "Josefin Slab", serif;
  line-height: 27px;
  font-size: 18px;
  text-align: right;
  pointer-events: none;
  animation: message-frames 1.5s ease 1s forwards;
  opacity: 0;

  @keyframes message-frames {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}


.palpitantAvatar{
  transform: scale(1,1);
  animation: palpitant 5s infinite;

  @keyframes palpitant {
    0%{
      transform: scale(1,1);
    }
    50%{
      transform: scale(1.05, 1.05);
    }
    100%{
      transform: scale(1, 1);
    }
    
  }
}

.BotonAGris{
  background-color: #ffffff00;
  cursor: pointer;
  &:hover{
    animation: colorg 1s ease;

    @keyframes colorg {
      from {
        background-color: #ffffff00;
      }
      to{
        background-color: #49545c2a;
      }
    }
  }
}

.motionButton{
  .motionText{
    opacity: 1;}
  &:hover{
    .motionText{
      opacity: 1;
      animation: apearmotion 1s ease;
    }
  
    @keyframes apearmotion {
      from {
        opacity: 0;
      }
      to{
        opacity: 1;
      }
    }
  }
  
}